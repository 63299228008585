import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import { Link } from "gatsby"
import { graphql, useStaticQuery } from 'gatsby';
import { BgImage } from 'gbimage-bridge';
import { OutboundLink } from "gatsby-plugin-google-gtag"

// FontAwesome Icons for Nav
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSatelliteDish, faWrench, faVideo, faFileVideo, faImages, faCode, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
library.add(faSatelliteDish, faWrench, faVideo, faFileVideo, faImages, faCode, faVolumeUp)


// markup
const IndexPage = () => {


  const { headerImage } = useStaticQuery(
    graphql`
query {
  headerImage: file(relativePath: { eq: "cover-services-2800x1800-46329375249.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
        layout: FULL_WIDTH
      )
    }
  }
}
`
  );







  return (

    <Layout pageTitle="Services">







      <BgImage Tag="section" className="py-10 py-md-10 overlay overlay-black overlay-60 bg-cover" image={headerImage.childImageSharp.gatsbyImageData} alt="">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">

              <h1 className="display-2 fw-bold text-white text-shadow">
                Services
              </h1>

              <p className="lead text-white-75 mb-0 text-shadow">
                Providing video production, engineering, live streaming, and audio services.
              </p>

            </div>
          </div>
        </div>
      </BgImage>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x text-light">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="white" />
          </svg>
        </div>
      </div>














      {/* Live Video & Event Production */}

      <section>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-md-6 col-lg-5 order-md-2">

              <div className="d-flex flex-column w-md-130 mb-6 mb-md-0 mt-6 mt-md-0" data-aos="fade-left" data-aos-delay="100">

                <StaticImage className="img-fluid rounded shadow align-self-start" data-jarallax-element="-20" src="../../images/services-live-commencement-bdr-1320x1600-48386934446.jpg" alt="Directing Commencement at Biola University" style={{ minHeight: '1px', maxWidth: '50%', marginLeft: '40%' }} />

                <StaticImage className="img-fluid rounded shadow align-self-start" data-jarallax-element="-50" src="../../images/services-live-commencement-drivethru-1320x2080.jpg" alt="Directing a pandemic Commencement at Biola University" style={{ minHeight: '1px', maxWidth: '50%', marginTop: '-35%' }} />

                <StaticImage className="img-fluid rounded shadow align-self-center" data-jarallax-element="-80" src="../../images/services-live-dolby-tndv-1320x880.jpg" alt="Working in TNDV truck at the Dolby Theater" style={{ minHeight: '1px', maxWidth: '50%', marginTop: '-20%' }} />

              </div>

            </div>
            <div className="col-12 col-md-6 order-md-1 pt-md-9" data-aos="fade-right">

              <span className="badge rounded-pill bg-primary mb-3">
                <span className="h6 text-uppercase"><FontAwesomeIcon icon="satellite-dish" size="1x" className="me-1" /> Live Video &amp; Event Production</span>
              </span>

              <h2 className="fw-bold">
                We're doing it <span className="fw-bolder text-primary">live</span>!
              </h2>

              <p className="fs-lg text-gray-700 mb-6">
                Nothing beats the adrenaline rush of going live. With <strong>thousands of hours of experience</strong>, I have the expertise to make your event shine.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                While I <strong>thrive under pressure</strong>, I believe my greatest contribution to live events is in doing extensive preparations ahead of time. Once you go live, there's no hiding from the world whether you've done your homework or not — you only get one shot!
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I'm most commonly a director of multi-camera live events (frequently tech directing for myself), but I'm always happy to serve in any role on a crew.
              </p>


              <div className="row">
                <div className="col-12 col-lg-6">

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Directing</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Tech Directing</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Producing</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Playout</p>
                  </div>

                </div>
                <div className="col-12 col-lg-6">

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus"></i></div>
                    <p className="text-gray-700">Graphics creation and operation</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus"></i></div>
                    <p className="text-gray-700">Shading</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus"></i></div>
                    <p className="ttext-gray-700">Camera operating</p>
                  </div>

                </div>
              </div>

              <p className="pt-4">
                <Link to="/work/live/" className="fw-bold text-decoration-none">
                  See examples of live events I've been a part of <i className="fe fe-arrow-right ms-2"></i>
                </Link>
              </p>

            </div>
          </div>
        </div>
      </section>











      <hr class="hr-md my-10 py-0 mx-auto d-md-none" />














      {/* Video Engineering */}




      <section className="my-10 my-md-10">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 mb-6" data-aos="fade-right" data-aos-delay="100">

              <StaticImage className="img-fluid rounded shadow align-self-start mw-md-130" src="../../images/services-engineering-diagram-1410x1260.png" alt="Excerpt of a system wiring diagram" />

            </div>

            <div className="col-12 col-md-6" data-aos="fade-left">

              <span className="badge rounded-pill bg-primary mb-3">
                <span className="h6 text-uppercase"><FontAwesomeIcon icon="wrench" size="1x" className="me-1" /> Video Engineering</span>
              </span>

              <h2 className="fw-bold">
                Engineering reliable <span className="fw-bolder text-primary">video systems</span> and workflows.
              </h2>

              <p className="fs-lg text-gray-700 mb-6">
                Whether it's doing a permanent install, building a flypack, or designing a temporary system for a single event, I'm passionate about creating video systems that won't flake out.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I design flexible systems that reduce labor, are simple to operate, and — most importantly — have multiple backups for critical functions.
              </p>

              <div className="row">
                <div className="col-12 col-lg-6">

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Flypacks</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Permanent installs</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Switching and routing</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Graphics and playout</p>
                  </div>

                </div>
                <div className="col-12 col-lg-6">

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Fiber multiplexing</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Automation</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Comm and tally</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Closed captions</p>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
      </section>











      <hr class="hr-md my-10 py-0 mx-auto d-md-none" />









      {/* Video Production */}




      <section className="pt-4 pt-md-0 pb-8 pb-md-8 pb-lg-8">
        <div className="container">
          <div className="row align-items-center justify-content-between">


            <div className="col-12 col-md-6 order-md-2 mb-6">

              <div className="row">
                <div className="col-6 mt-8 me-n5" data-aos="fade-up" data-aos-delay="100">

                  <StaticImage src="../../images/services-video-interview-movi-1320x880-38937479391.jpg" alt="My team shooting an interview." className="img-fluid mb-4 rounded" />

                  <StaticImage src="../../images/services-video-interview-studioa-1320x2080-46329377503.jpg" alt="Shooting an interview for the CMA program video." className="img-fluid mb-4 rounded" />

                  <StaticImage src="../../images/services-video-studio-rnh-1320x1050.jpg" alt="Stylized shoot for Athletics" className="img-fluid rounded" />

                </div>
                <div className="col-6" data-aos="fade-up" data-aos-delay="200">

                  <StaticImage src="../../images/services-video-interview-blackstone-1320x1050-46329232472.jpg" alt="David interviewing a subject for a program video." className="img-fluid mb-4 rounded" />

                  <StaticImage src="../../images/services-video-red-dtla-1320x2080-51712313255.jpg" alt="Video shoot with a RED camera." className="img-fluid mb-4 rounded" />

                  <StaticImage src="../../images/services-video-camera-1320x880-46329396503.jpg" alt="Camera" className="img-fluid rounded" />

                </div>
              </div>

            </div>



            <div className="col-12 col-md-6 col-lg-6" data-aos="fade-right">


              <span className="badge rounded-pill bg-primary mb-3">
                <span className="h6 text-uppercase"><FontAwesomeIcon icon="video" size="1x" className="me-1" /> Video Production</span>
              </span>

              <h2 className="fw-bold mb-6">
                Telling powerful stories through <span className="fw-bolder text-primary">video</span>.
              </h2>

              <p className="fs-lg text-gray-700 mb-6">
                From concept to completion, producing videos is my bread-and-butter. I oversee nearly a hundred video projects each year in my role at Biola University.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I have extensive experience with a variety of video production equipment, including: cameras from Sony, Blackmagic, RED, and Arri; lighting from Litepanels, Hive, Aputure, Quasar Science, and Arri; audio from Sound Devices, Schoeps, Shure, Sennheiser, and Røde; and support gear from Movi, DJI, Teradek, and SmallHD.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I'm certified in editing with Blackmagic DaVinci Resolve 17 — plus I also have years of experience with Adobe Premiere Pro, Apple Final Cut Pro, and Adobe After Effects.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                On top of all that, I'm also a licensed drone pilot (FAA Part 107).
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                But before I touch any of that gear, I always take the time to drill down into the "why" behind each project — figuring out the unique way that each project can be a success.
              </p>



              <div className="row">
                <div className="col-12 col-lg-6">

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Writing</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Producing</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Directing</p>
                  </div>

                </div>
                <div className="col-12 col-lg-6">

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Shooting</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Editing</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Delivering</p>
                  </div>

                </div>
              </div>


              <p className="pt-4">
                <Link to="/work/video/" className="fw-bold text-decoration-none">
                  See examples of videos I've worked on <i className="fe fe-arrow-right ms-2"></i>
                </Link>
              </p>


            </div>



          </div>
        </div>
      </section>















      <hr class="hr-md mb-10 py-0 mx-auto d-md-none" />









      {/* Photography */}




      <section className="pt-4 pt-md-0 pb-8 pb-md-8 pb-lg-8">
        <div className="container">
          <div className="row align-items-center justify-content-between">


            <div className="col-12 col-md-6 mb-6">

              <div className="row">
                <div className="col-6 mt-8 me-n5" data-aos="fade-up" data-aos-delay="100">

                  <StaticImage src="../../images/services-photo-spraypaint-1320x1050-42153650595.jpg" alt="Photo of art student spraypainting" className="img-fluid mb-4 rounded" />

                  <StaticImage src="../../images/services-photo-hiking-1320x1600-42152917711.jpg" alt="Photo of students hiking in the Southern California hills" className="img-fluid rounded" />

                </div>
                <div className="col-6" data-aos="fade-up" data-aos-delay="200">

                  <StaticImage src="../../images/services-photo-soccer-1320x1600-37644700214.jpg" alt="Aerial photo of soccer players huddling" className="img-fluid mb-4 rounded" />

                  <StaticImage src="../../images/services-photo-griffith-1320x1050.jpg" alt="Photo of Griffith Park at sunset" className="img-fluid rounded" />

                </div>
              </div>

            </div>



            <div className="col-12 col-md-6 col-lg-6" data-aos="fade-left">


              <span className="badge rounded-pill bg-primary mb-3">
                <span className="h6 text-uppercase"><FontAwesomeIcon icon="images" size="1x" className="me-1" /> Photography</span>
              </span>

              <h2 className="fw-bold mb-6">
                A <span className="fw-bolder text-primary">photo</span> is worth a thousand words.
              </h2>

              <p className="fs-lg text-gray-700 mb-6">
                I've shot a variety of types of photography — ranging from campaigns, to portraits, to candids, to architecture, to events — and have been fortunate to have my work featured on the web, in magazines, advertisements, banners, and even billboards.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I'm most fluent with Sony and Canon bodies, strobes from Profoto, and editing in Lightroom and Capture One.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I also occasionally enjoy the challenge of shooting with only my iPhone.
              </p>

              <p className="pt-4 pb-0 mb-0">
                <Link to="/work/photo/" className="fw-bold text-decoration-none">
                  See examples of my photography <i className="fe fe-arrow-right ms-2"></i>
                </Link>
              </p>
              <p className="pt-0 mt-0">
                <OutboundLink href="https://www.instagram.com/dbaxter" className="fw-bold text-decoration-none" target="_blank" rel="noopener noreferrer">
                  Check out my Instagram <i className="fe fe-arrow-right ms-2"></i>
                </OutboundLink>
              </p>



            </div>



          </div>
        </div>
      </section>













      <hr class="hr-md mb-10 py-0 mx-auto d-md-none" />









      {/* Streaming & Encoding */}




      <section className="my-9 mt-md-8">
        <div className="container">

          <div className="row align-items-center justify-content-between">



            <div className="col-12 col-md-9 order-md-1" data-aos="fade-right">

              <span className="badge rounded-pill bg-primary mb-3">
                <span className="h6 text-uppercase"><FontAwesomeIcon icon="file-video" size="1x" className="me-1" /> Streaming &amp; Encoding</span>
              </span>

              <h2 className="fw-bold">
                Pixel-perfect <span className="fw-bolder text-primary">encoding + streaming</span>.
              </h2>

              <p className="fs-lg text-gray-700 mb-6">
                What good is creating a video if nobody can watch it? Whether the destination is YouTube, a custom web player, or a mobile device, I have over two decades of experience in encoding and delivering video content.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                For on-demand videos, my experience ranges from desktop encoders like Adobe Media Encoder, Apple Compressor, or Handbrake, to enterprise solutions like Telestream Vantage and AWS Elemental MediaConvert, to custom solutions involving FFmpeg, x264, and scripting.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                For live streams, I've stood up robust systems to deliver crystal-clear HD to thousands simultaneously, as well as simulcast to multiple social platforms — utilizing server technologies like Wowza Streaming Engine, AWS EC2, AWS Elemental MediaLive, and NGINX; and encoders like the Teradek Cube, Wowza ClearCaster, AJA HELO, Resi, and OBS.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I have experience delivering multi-bitrate, buffer-free video via CDNs like AWS CloudFront, Edgecast, Akamai, plus in-house NGINX proxy caching.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I also have extensive experience dealing with closed captions — embedded in video files, as sidecars, and as 608/708 streams in SDI video.
              </p>


            </div>



            <div className="col-12 col-md-3 order-md-2" data-aos="fade-left" data-aos-delay="100">

              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                <p className="text-gray-700">Encoding H.264 and H.265</p>
              </div>

              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                <p className="text-gray-700">RTMP and HLS delivery</p>
              </div>

              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                <p className="text-gray-700">CDN origin and edge configuration</p>
              </div>

              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                <p className="text-gray-700">Closed captioning</p>
              </div>

              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                <p className="text-gray-700">Custom players and embeds</p>
              </div>

              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                <p className="text-gray-700">Delivery to platforms like YouTube, Facebook, Twitter, etc.</p>
              </div>



            </div>



          </div>
        </div>
      </section>














      <hr class="hr-md my-10 py-0 mx-auto d-md-none" />














      {/* Cloud/Server Admin + Programming */}



      <section className="my-6 my-md-10">
        <div className="container">
          <div className="row justify-content-between align-items-center">


            <div className="col-12 col-md-8 order-2 order-md-1" data-aos="fade-right">

              <span className="badge rounded-pill bg-primary mb-3">
                <span className="h6 text-uppercase"><FontAwesomeIcon icon="code" size="1x" className="me-1" /> Cloud/Server Admin + Programming</span>
              </span>

              <h2 className="fw-bold">
                Talk <span className="fw-bolder text-primary">nerdy</span> to me.
              </h2>

              <p className="fs-lg text-gray-700">
                Not afraid to drop down to the terminal, or dive into the intricacies of the cloud.
              </p>

              <p className="fs-lg text-gray-700">
                I've implemented multiple production workflows in Amazon Web Services (AWS) EC2, S3, CloudFront, Lambda, and Lightsail.
              </p>

              <p className="fs-lg text-gray-700">
                I regularly manage multiple Linux-based servers, including Network Attached Storage (NAS), Digital Asset Management (DAM), SQL database, app, and utility servers.
              </p>

              <p className="fs-lg text-gray-700">
                Proud of scripts I've created utilizing Python, PHP, and bash to streamline various workflows — saving hundreds of hours of labor each year — and connect disparate workflows.
              </p>

              <p className="fs-lg text-gray-700">
                Experienced with various web technologies like HTML, CSS, React, Gatsby, Node, SQL, GraphQL, JSON, and XML.
              </p>

              <p className="fs-lg text-gray-700">
                Implemented high-performance 40Gbps networking. Currently working towards my Cisco CCNA.
              </p>



            </div>



            <div className="col-12 col-md-4 img-skewed img-skewed-start order-1 order-md-2" data-aos="fade-left" data-aos-delay="100">

              <div className="card rounded-lg bg-black mb-6 mb-md-0 img-skewed-item screenshot">
                <div className="card-header border-white-10">

                  <div className="d-flex">
                    <span className="bg-danger rounded-circle" style={{ width: '10px', height: '10px' }}></span>
                    <span className="bg-warning rounded-circle ms-1" style={{ width: '10px', height: '10px' }}></span>
                    <span className="bg-success rounded-circle ms-1" style={{ width: '10px', height: '10px' }}></span>
                  </div>

                </div>
                <div className="card-body">

                  <code className="highlight">
                    &gt;&nbsp;<span className="text-success">Deployed multiple production workflows to AWS</span><br />< br />
                    &gt;&nbsp;<span className="text-success">Manage Linux servers, including NAS</span><br /><br />
                    &gt;&nbsp;<span className="text-success">Experienced with web technologies</span><br /><br />
                  </code>

                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
























      <hr class="hr-md my-10 py-0 mx-auto d-md-none" />












      {/* Audio */}



      <section className="py-8 py-md-8">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 mb-6" data-aos="fade-right" data-aos-delay="100">

              <StaticImage className="img-fluid rounded shadow align-self-start mw-md-130" src="../../images/services-audio-sd7-1410x1260.jpg" alt="Live sound at an event at the Dolby Theater" />

            </div>

            <div className="col-12 col-md-6" data-aos="fade-left">

              <span className="badge rounded-pill bg-primary mb-3">
                <span className="h6 text-uppercase"><FontAwesomeIcon icon="volume-up" size="1x" className="me-1" /> Audio</span>
              </span>

              <h2 className="fw-bold">
                <span className="fw-bolder text-primary">Listening</span> to every detail.
              </h2>

              <p className="fs-lg text-gray-700 mb-6">
                I'm experienced with recording, mixing, and editing audio — including for video, podcasts, and audiobooks — using tools like Adobe Audition, Apple Logic, Fairlight, Reaper, and iZotope RX.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                Comfortable mixing for live events (music and speech) on DiGiCo, Allen &amp; Heath, Yamaha, Midas, and Soundcraft.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I've implemented Dante audio networking — and am certified Level 1, 2, and 3. I've set up and frequency coordinated large wireless mic, IEM, and antenna systems from Shure and RF Venue — even in the crowded spectrum of Downtown Hollywood.
              </p>

              <div className="row">
                <div className="col-12 col-lg-6">

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Dante certified</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Live event mixing and system engineering</p>
                  </div>

                </div>
                <div className="col-12 col-lg-6">

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Podcast recording and mixing</p>
                  </div>

                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-dark-soft mt-1 me-4"><i className="fe fe-plus text-gray-600"></i></div>
                    <p className="text-gray-700">Proper gain structure is music to my ears</p>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
      </section>






























































































      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x text-dark">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" /></svg>      </div>
      </div>

      <section className="py-8 pt-md-11 pb-md-6 bg-gradient-dark-black">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">

              <h1 className="fw-bold text-white">
                Want to explore more?
              </h1>

              <p className="fs-lg text-gray-100 mb-7 mb-md-9">
                Check out some of <Link to="/work/">my past work</Link>, learn more <Link to="/about/">about me</Link>, or <Link to="/contact/">get in touch</Link>.
              </p>

            </div>
          </div>


        </div>
      </section>





























    </Layout>


  )
}

export default IndexPage
